import { Link } from "react-router-dom";
const WordBubbles = () => {
  return ( 
      <div> 
        <iframe src="https://bomextest.org/Widgets/Bubbles/index.html" style={{height: "800px", width: "100%", border:"none"}} ></iframe>
        <br/>
        {/* This component is present in all pages except Feedback */}
        <Link to="/Feedback" className="footer"/>
      </div>  
     );
}
 
export default WordBubbles;