import { useParams, useNavigate } from "react-router-dom";
import data from "./people_data.json";
import ImageLoader from "./ImageLoader";
import Collapsible from "./Collapsible";
import Analysis_Loader from "./Analysis_Loader";
import NotFound from "./NotFound";
import { Link } from "react-router-dom";

const PeopleDetails = () => {
  const { speaker_tag } = useParams()
  const speakers = data.people;

  const speakerObject = speakers.reduce((acc, curr) => curr.link === speaker_tag ? curr : acc, null);

  if (speakerObject.analysis_1 == null) {//is a category with a landing page. Speaker categories have imgs
    return (speakerObject ?
      <div>      
      <p style={{borderBottom: "inset", borderWidth:"6px", height:"3em", width:"94vw", textAlign:"center", padding:"3vw"}}>
        <h3>More about {speakerObject.name}</h3>
      </p>

      <div className="cameo-list-all first-element-spacing">
        <img className="speaker-detail-outer-img" src={ImageLoader(speakerObject.img)} alt="" style={{flexWrap:'wrap'}}/>
        {speakerObject.data.map((speaker) => (
            <Link key={speaker.id} className="cameo-list-all-card" to={ '/cameos/people/' + speaker_tag + '/' + speaker.link } style={{width:'fit-content',marginBottom:'1em'}}>
              <h4>{ 
 	              <div dangerouslySetInnerHTML={{__html: speaker.name}} />
	      }</h4>
            </Link>
        ))}
      </div>
      <br/>
      {/* This component is present in all pages except Feedback */}
      <Link to="/Feedback" className="footer"/>
  </div>
  : <NotFound></NotFound>
  );
  }


  //else is a cameo
  var wordCount = null
  if (speakerObject.word_count != 0)
    wordCount = <h4>Word count: { speakerObject.word_count} <br/></h4>
  var nonCollapsible1;
  if (speakerObject.analysis_1 != null)
    if (speakerObject.analysis_1.includes("chart")) {
        nonCollapsible1 = <div><Analysis_Loader id={speakerObject.analysis_1}/></div>;
    }
  var nonCollapsible2;
  if (speakerObject.analysis_2 != null)
    if (speakerObject.analysis_2.includes("chart")) {
        nonCollapsible2 = <div><Analysis_Loader id={speakerObject.analysis_2}/></div>;
    }

  return ( speakerObject ? 
    <article className='speaker-details'>
      <img className="speaker-detail-img" src={ImageLoader(speakerObject.img)}></img>
      <h3 style={{marginBottom: '1.03423086758vw'}}>
        {  
 	  <div dangerouslySetInnerHTML={{__html: speakerObject.name}} />
	}
      </h3>
      { wordCount }
      <p className="speaker-description" style={{marginTop: '2vw', marginBottom: '0.03423086758vw'}}>
	{
 	  <div dangerouslySetInnerHTML={{__html: speakerObject.description}} />
	}

      </p>
      { speakerObject.fact_1 && speakerObject.analysis_1 &&  <Collapsible className='collapsible' header_text={speakerObject.fact_1} body_text={<Analysis_Loader id={speakerObject.analysis_1}/>} />}
      <br></br>
      { speakerObject.fact_2 && speakerObject.analysis_2 && <Collapsible className='collapsible' header_text={speakerObject.fact_2} body_text={<Analysis_Loader id={speakerObject.analysis_2}/>}/>}
      <p className="noncollapsible-analysis">
        { nonCollapsible1 }
      </p>
      <p className="noncollapsible-analysis">
        { nonCollapsible2 }
      </p>
      <br/><br/>
      {/* This component is present in all pages except Feedback */}
      <Link to="/Feedback" className="footer"/>
    </article>
  : <NotFound></NotFound>
   );
}
 
export default PeopleDetails;
