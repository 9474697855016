import { Link } from 'react-router-dom';
import logo from './images/SC-Book-Of-Mormon-Central-Favicon-Black-32px.png';
import icon from './images/icon.png';
import { useState } from 'react';
import NavBarExpansion from './NavBarExpansion';
import navHamburgerMenu from './images/hamburger-menu.svg';
const Navbar = () => {
  const reactiveHoverItems = [
    // 'navbar-speakers-header', 
    // 'navbar-concepts-header', 
    // 'navbar-influences-header', 
    'navbar-cameos-header',
    'navbar-toolbox-header', 
    'nav-hamburger-container'
  ]
  let reactiveHoverStatesObject = {};
  const reactiveHoverStates = reactiveHoverItems.forEach((item) => { reactiveHoverStatesObject[item] = false; });
  const [hoverStates, setHoverStates] = useState(reactiveHoverStatesObject);

  const cameosExpandableItems = [
    'Major Speakers',
    'Minor Speakers',
    'Concepts',
    'Influences'
  ]


  const cameosExpandableLinks = [
    '/cameos/speakers',
    '/cameos/people',
    '/cameos/concepts',
    '/cameos/influences'
  ]

  /*
  const speakersExpandableItems = [
    'Abinadi', 
    'Alma the Younger', 
    'Ammon', 
    'Amulek', 
    'Benjamin', 
    'Captain Moroni', 
    'Giddianhi',
    'Helaman',
    'Jacob',
    'Korihor',
    'Lehi',
    'Mormon',
    'Moroni, Son of Mormon',
    'Mosiah',
    'Nephi, Son of Helaman',
    'Nephi, Son of Lehi',
    'Pahoran',

    'Sariah',
    'Amaleki',
    "Lamanite Kings: Lamoni, His Father, and Anti-Nephi-Lehi"
   ];

  

   const speakersExpandableLinks = speakersExpandableItems.map(speakerExpandableItem => {
    return `speakers/${speakerExpandableItem.toLowerCase().replace(/[\W_]+/g, '-')}`;
   });
  */

  // now refers to json file instead of deriving with regex of hardcoded names
  // const speakersExpandableItems = []
  // for (const i in data) {
  //   const speaker = data[i]
  //   if (speaker.img != "") speakersExpandableItems.push(speaker.name)
  // }

  // const speakersExpandableLinks = speakersExpandableItems.map(speakerExpandableItem => {
  //   for (const i in data) {
  //     const speaker = data[i]
  //     if (speaker.name == speakerExpandableItem) return `speakers/${speaker.link}`
  //   }
  //   return `speakers/`;
  // });


  /*
   const conceptsExpandableItems = [
    "Bowing Down Before God",
    "Great Spirit", 
    "The Merits of the Messiah or the Son of God", 
    "A Portion of His Spirit", 
    "Ammon, a Prophet Like Moses"
   ]

   const conceptsExpandableLinks = conceptsExpandableItems.map(conceptExpandableItem => {
    return `concepts/${conceptExpandableItem.toLowerCase().replace(/[\W_]+/g, '-')}`;
   })
  */

  // now refers to json file instead of deriving with regex of hardcoded names
  // const conceptsExpandableItems = []
  // for (const i in data) {
  //   const concept = data[i]
  //   if (concept.img == "" && concept.word_count == 0) conceptsExpandableItems.push(concept.name)
  // }

  // const conceptsExpandableLinks = conceptsExpandableItems.map(conceptsExpandableItem => {
  //   for (const i in data) {
  //     const concept = data[i]
  //     if (concept.name == conceptsExpandableItem) return `concepts/${concept.link}`
  //   }
  //   return `concepts/`;
  // });



  // const influencesExpandableItems = []
  // for (const i in data) {
  //   const influence = data[i]
  //   if (influence.word_count == -1) influencesExpandableItems.push(influence.name)
  // }

  // const influencesExpandableLinks = influencesExpandableItems.map(influencesExpandableItem => {
  //   for (const i in data) {
  //     const influence = data[i]
  //     if (influence.name == influencesExpandableItem) return `influences/${influence.link}`
  //   }
  //   return `influences/`;
  // });


  const toolboxExpandableItems = [
//    'Book of Mormon Explorer VR',
    'Timeline',
    'Word Bubbles',
    'Connections',
    'Network Diagram',
    'Book of Mormon Semantic Map',
    // 'Holy Bible Semantic Map',
    'Similar Verse Finder',
    'Social Network'
   ]; 

  const toolboxExpandableLinks = [
 //   '/toolbox/stylo-xr',
    '/toolbox/timeline',
    '/toolbox/word-bubbles',
    '/toolbox/connections',
    '/toolbox/network-diagram',
    '/toolbox/BOM-semantic-map',
    // '/toolbox/Bible-semantic-map',
    '/toolbox/similar-verse',
    '/toolbox/social-network'
  ];

  const hamburgerExpandableItems = [
    'Home',
    'About',
    'Cameos',
    'Toolbox',
    'Contact'
  ];

  const hamburgerExpandableLinks = [
    '/',
    '/about',
    '/cameos',
    '/toolbox',
    '/feedback'
  ];
  
  const toggleIsHovering = (event) => {
    //console.log(event.currentTarget.id);
    const adjHoverStates = {...hoverStates};
    console.log('EVENT.CURRENTTARGET.ID: ' + event.currentTarget.id);
    //console.log('ADJ HOVERING (SHOULD BE SAME AS ORIGINAL IS HOVERING)')
    //console.log(adjHoverStates);
    //console.log('---------------------------------');
    //console.log('ELEMENT TO CHANGE: (SHOULD BE EQUAL TO THE ID OF THE HEADER)');
    //console.log(event.currentTarget.id);
    //console.log('-----------------------------------');
    adjHoverStates[event.currentTarget.id] = !adjHoverStates[event.currentTarget.id];
    //console.log('ADJUSTED HOVERING (SHOULD REFLECT CHANGE IN EXPANDABLE THAT NEEDS TO OCCUR WITH A TRUE VALUE');
    //console.log(adjHoverStates);
    //console.log('-------------------------------------');
    let filteredHovering = {};
    Object.entries(adjHoverStates).forEach(([key, value]) => key === event.currentTarget.id ? filteredHovering[key] = value : filteredHovering[key] = false);
    //console.log('FILTERED HOVERING (SHOULD CONTAIN THE OPPOSITE VALUE AS PREV IN THE CURRENT TARGET ID AND ALL FALSE OTHERWISE');
    //console.log(filteredHovering);
    //console.log('-------------------------------------')
    setHoverStates(filteredHovering);
    console.log(filteredHovering);
  }
//logo is favicon
  return (  
    <nav className="navbar">
      <div className="nav-title">
        <Link className='logo-container' to="/">
            <img className='logo' src={logo} alt="Book of Mormon Voices Logo"  />
        </Link>
        <Link to='/'>
          <img className='icon' src={icon} alt="Book of Mormon Voices Icon"/>
        </Link>
        <Link className='site-title' to="/">
            <h1>BOOK OF MORMON EXPLORER</h1>
        </Link>
      </div>

      <div className="nav-links">
        <Link to="/" className='nav-header'>Home</Link>
        <Link to="/about" className='nav-header'>About</Link>


        <Link to="/cameos" id={reactiveHoverItems[0]} className='nav-header' onMouseEnter={toggleIsHovering} onMouseLeave={toggleIsHovering}>
          Cameos
          <span className="down-carrot"> &#9660;</span>
          {hoverStates[reactiveHoverItems[0]] && (
          <NavBarExpansion className='navbar-expanded-menu' expandableItems={cameosExpandableItems} expandableLinks={cameosExpandableLinks} style={{width: 'fit-content', top: '60px', left: '-8vw'}}></NavBarExpansion>
        )}
        </Link>

        <Link to="/toolbox" id={reactiveHoverItems[1]} className='nav-header' onMouseEnter={toggleIsHovering} onMouseLeave={toggleIsHovering}>
          Toolbox
          <span className="down-carrot"> &#9660;</span>
          {hoverStates[reactiveHoverItems[1]] && (
          <NavBarExpansion className='navbar-expanded-menu' expandableItems={toolboxExpandableItems} expandableLinks={toolboxExpandableLinks} style={{width: 'fit-content', top: '60px', left: '-8vw'}}></NavBarExpansion>        
        )}
        </Link>

        <Link to="/Feedback" className='nav-header'>Contact</Link>

      </div>
      <div id={reactiveHoverItems[2]} className='nav-hamburger-container'  onMouseEnter={toggleIsHovering} onMouseLeave={toggleIsHovering}>
        <svg className='nav-hamburger-menu' viewBox='0 -13 100 100'>
          <image className='hamburger-svg-image' width='100' height='73' viewbox='0 0 100 100' href={navHamburgerMenu}/>
        </svg>
        {hoverStates[reactiveHoverItems[2]] && (
            <NavBarExpansion className='navbar-expanded-menu' expandableItems={hamburgerExpandableItems} expandableLinks={hamburgerExpandableLinks} style={{position: 'absolute', width: '14vw', height: 'auto', top: 'calc(max(min(36px, 3vw), 16px) + 2vw)', right: '0px', zIndex: 9}}></NavBarExpansion>
          )}
      </div>
    </nav>
  );
}
 
export default Navbar;

/*
        <Link to="/faq" className='nav-header'>FAQ</Link>



                <Link to="/cameos/speakers" id={reactiveHoverItems[0]} className='nav-header' onMouseEnter={toggleIsHovering} onMouseLeave={toggleIsHovering}>
          Speakers
          <span className="down-carrot"> &#9660;</span>
          {hoverStates[reactiveHoverItems[0]] && (
          <NavBarExpansion className='navbar-expanded-menu' expandableItems={speakersExpandableItems} expandableLinks={speakersExpandableLinks} style={{width: '48vw', height: '500px', blocksize: 'fit-content', top: '60px', left: '-16vw'}}></NavBarExpansion>
        )}
        </Link>

        <Link to="/cameos/people" id={reactiveHoverItems[2]} className='nav-header' onMouseEnter={toggleIsHovering} onMouseLeave={toggleIsHovering}>
          People
          <span className="down-carrot"> &#9660;</span>
          {hoverStates[reactiveHoverItems[2]] && (
          <NavBarExpansion className='navbar-expanded-menu' expandableItems={influencesExpandableItems} expandableLinks={influencesExpandableLinks} style={{width: '78vw', height: '620px', top: '60px', left: '-58vw'}}></NavBarExpansion>
        )}
        </Link>

        <Link to="/cameos/concepts" id={reactiveHoverItems[1]} className='nav-header' onMouseEnter={toggleIsHovering} onMouseLeave={toggleIsHovering}>
          Concepts
          <span className="down-carrot"> &#9660;</span>
          {hoverStates[reactiveHoverItems[1]] && (
          <NavBarExpansion className='navbar-expanded-menu' expandableItems={conceptsExpandableItems} expandableLinks={conceptsExpandableLinks} style={{width: '68vw', height: '630px', top: '60px', left: '-42vw'}}></NavBarExpansion>
        )}
        </Link>

        <Link to="/cameos/influences" id={reactiveHoverItems[2]} className='nav-header' onMouseEnter={toggleIsHovering} onMouseLeave={toggleIsHovering}>
          Influences
          <span className="down-carrot"> &#9660;</span>
          {hoverStates[reactiveHoverItems[2]] && (
          <NavBarExpansion className='navbar-expanded-menu' expandableItems={influencesExpandableItems} expandableLinks={influencesExpandableLinks} style={{width: '78vw', height: '620px', top: '60px', left: '-58vw'}}></NavBarExpansion>
        )}
        </Link>
*/
