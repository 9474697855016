import { Link } from "react-router-dom";
const SocialNetwork = () => {
    return ( 
      <div> 
        <iframe src="https://bomextest.org/Widgets/SocialNetwork/index.html" style={{height: "800px", width: "100%", border:"none"}} ></iframe>
        <br/><br/><br/>
        {/* This component is present in all pages except Feedback */}
        <Link to="/Feedback" className="footer"/>
      </div>
     );
  }
   
  export default SocialNetwork;
  