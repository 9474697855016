import { useParams, useNavigate} from "react-router-dom";
import data from "./speaker_data.json";
import ImageLoader from "./ImageLoader";
import Collapsible from "./Collapsible";
import Analysis_Loader from "./Analysis_Loader";
import NotFound from "./NotFound";
import { Link } from "react-router-dom";

const SpeakerDetailsInner = () => {
  const { category, speaker_tag } = useParams()
  const speakers = data.speakers;

  // find the cameo. it's hidden within a category element
  const speakersInner = speakers.reduce((acc, curr) => curr.link === category ? curr : acc, null);
  const speakerObject = speakersInner.data.reduce((acc, curr) => curr.link === speaker_tag ? curr : acc, null);

  var wordCount = null
  if (speakerObject.word_count != 0)
    wordCount = <h4>Word count: { speakerObject.word_count} <br/></h4>
  var nonCollapsible1;
  if (speakerObject.analysis_1 != null)
    if (speakerObject.analysis_1.includes("chart")) {
        nonCollapsible1 = <div><Analysis_Loader id={speakerObject.analysis_1}/></div>;
    }
  var nonCollapsible2;
  if (speakerObject.analysis_2 != null)
    if (speakerObject.analysis_2.includes("chart")) {
        nonCollapsible2 = <div><Analysis_Loader id={speakerObject.analysis_2}/></div>;
    }
  return ( speakerObject ? 
    <article className='speaker-details'>
      <img className="speaker-detail-img" src={ImageLoader(speakerObject.img)}></img>
      <h3 style={{marginBottom: '1.03423086758vw'}}>
        { 
		<div dangerouslySetInnerHTML={{__html: speakerObject.name}} /> 
	} <br/>
      </h3>
      { wordCount }
      <p className="speaker-description" style={{marginBottom: '1.03423086758vw'}}>
        { 
		<div dangerouslySetInnerHTML={{__html: speakerObject.description}} /> 
	}
      </p>
      { speakerObject.fact_1 && speakerObject.analysis_1 &&  <Collapsible className='collapsible' header_text={speakerObject.fact_1} body_text={<Analysis_Loader id={speakerObject.analysis_1}/>} />}
      <br></br>
      { speakerObject.fact_2 && speakerObject.analysis_2 && <Collapsible className='collapsible' header_text={speakerObject.fact_2} body_text={<Analysis_Loader id={speakerObject.analysis_2}/>}/>}
      <p className="noncollapsible-analysis">
        { nonCollapsible1 }
      </p>
      <p className="noncollapsible-analysis">
        { nonCollapsible2 }
      </p>
      <br/><br/>
      {/* This component is present in all pages except Feedback */}
      <Link to="/Feedback" className="footer"/>
    </article>
  : <NotFound></NotFound>
   );
}
 
export default SpeakerDetailsInner;
