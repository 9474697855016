import { Link } from "react-router-dom";

const CameosAll = ( ) => {

    const paddingVar = "11vw"

  return ( 
    <div>      
      <p style={{borderBottom: "inset", borderWidth:"6px", height:"3em", textAlign:"center", padding:"3vw"}}>
        <h3>Stylometric analyses about Book of Mormon people and themes.</h3>
      </p>

      <div className="cameo-list-all first-element-spacing">
        <Link className="cameo-list-all-card" to={ '/cameos/speakers' }>
            <p>
                <h3> Major Speakers </h3>
                <p style={{fontSize:'smaller', textDecoration:'none'}}><br/>Core writers of the Book of Mormon</p>
            </p>
        </Link>
        
        <Link className="cameo-list-all-card" to={ '/cameos/people' }>
            <p>
                <h3> Minor Speakers </h3>
                <p style={{fontSize:'smaller', textDecoration:'none'}}><br/>Relatively few words</p>
            </p>
        </Link>

        <Link className="cameo-list-all-card" to={ '/cameos/concepts' }>
            <p>
                <h3> Concepts </h3>
                <p style={{fontSize:'smaller', textDecoration:'none'}}><br/>Understand their teachings</p>
            </p>
        </Link>

        <Link className="cameo-list-all-card" to={ '/cameos/influences' }>
            <p>
                <h3>Influences</h3>
                <p style={{fontSize:'smaller', textDecoration:'none'}}><br/>Read their scriptural sources</p>
            </p>
        </Link>
      </div>
      <br/>
      {/* This component is present in all pages except Feedback */}
      <Link to="/Feedback" className="footer"/>
  </div>
      
   );
}
 
export default CameosAll;