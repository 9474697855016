import { Link } from "react-router-dom";
import homeImage2 from './images/homepage_images/home-image-2.jpg';

const Home = () => {

  const links = 
  [  
    ['Read analyses of the Book of Mormon authors', '/cameos'],
    ['Check out our data-visualization tools', '/toolbox']
 //   ['Insights', '/insights'],
 //   ['Influences', '/influences'],
 //   ['Timeline', '/toolbox/timeline'],
 //   ['Word Bubbles', '/toolbox/word-bubbles'], 
 //   ['Connections', '/toolbox/connections'],
 //   ['Network Diagram', '/toolbox/network-diagram'],
 //   ['Similar Verse Finder', '/toolbox/similar-verse'],
 //   ['Book of Mormon Semantic Map', '/toolbox/BOM-semantic-map']
//    ['Audio', '/toolbox/audio']   // until this page is fixed
];

const backupLinks =
[
  ['Cameos', '/cameos'],
  ['Toolbox', '/toolbox']
]

  const defaultIndent = '4.3810714734vw';
  const bannerBulletSymbol = ""

  return ( 
    <>
      <div className="banner" style={{ marginBottom: '0.2vw' }}>
        
        <h1 className='main-text main-banner-text' style={{ marginRight: '30vw', lineHeight: '0.55', marginTop: '1.6vw'}}><em>You've read our words. Pondered our stories.</em></h1>
        <h1 id='now-hear-our-voice' className='main-banner-text main-text' style={{ marginRight: '35vw', lineHeight: '0.55', marginTop: '5vw' }}><em>Now, hear our voices.</em></h1>
        <p className='main-banner-text main-text' style={{marginTop: '9.01699437495vw'}}>
          Through careful research, advanced data analysis, and 
          compelling stylometry, scholars have discovered a fascinating 
          way to help each voice in the Book of Mormon come to life.
        </p>
        <p className='backup-banner-text main-banner-text main-text'>
          Helping each voice in the Book of Mormon come to life through research and data analysis
        </p>
        <div>
          <ol id='main-banner-links' className='main-text'>
            {links.map(([text, url], index) => (
              <Link to={url} key={index}>
                <h2 className='main-banner-link' style={{display: 'inline-block', float: 'left', clear: 'both', textIndent:'-.8em', left:'.8em', paddingTop:'10px'}}> 
                  {text} {bannerBulletSymbol}
                </h2>
              </Link>
            ))}
          </ol>
          <ol id='backup-banner-links' className='main-text'>
            {backupLinks.map(([text, url], index) => (
              <Link to={url} key={index}>
                <h2 className='main-banner-link' style={{display: 'inline-block', float: 'left', clear: 'both', textIndent:'-.8em', left:'.8em'}}> 
                  {text} {bannerBulletSymbol}
                </h2>
              </Link>
            ))}
          </ol>
        </div>
      </div>
      <hr style={{ marginTop: '2vw' }}></hr>
      <h2 style={{ textAlign: 'center', paddingTop: '2vw', paddingBottom: '2.5vw' }}><em>Experience the Book of Mormon</em></h2>
      <h1 style={{ textAlign: 'center', fontSize: '3vw', fontWeight: '100' }}><em>like you never have before.</em></h1>
      <div style={{margin: defaultIndent, marginRight:"2vw", marginLeft:'2vw', paddingBottom:'5vh', display: 'flex', flexDirection: 'row', justifyItems: 'space-around'}}>
        <div id='home-size-image-container'>
          <img id='home-side-image' src={homeImage2} style={{ width: `calc(calc(51vw - ${parseFloat(defaultIndent)}vw))`, height: '100%', marginRight: '2.5vw'}}></img>
        </div>
        <div>
          <p style={{ width: `calc(calc(50vw - ${parseFloat(defaultIndent)}vw))`, padding: '1vw', boxSizing: 'border-box' }}>Did you know there are 106 individuals who spoke in the Book of Mormon, with 24 primary voices? Each one is unique, each one a real living, breathing human being.</p>
          <p style={{ width: `calc(calc(50vw - ${parseFloat(defaultIndent)}vw))`, padding: '1vw', boxSizing: 'border-box' }}>Analyzing the data of their word and language patterns brings these speakers to life in an unprecedented way.</p>
          <p style={{ width: `calc(calc(50vw - ${parseFloat(defaultIndent)}vw))`, padding: '1vw', boxSizing: 'border-box' }}>Now, you get a chance to be part of this “getting to know you” exploration of people in the Book of Mormon. Come, discover their captivating and untold stories.</p>
          <button style={{ width: 'auto', padding: '1vw', boxSizing: 'border-box', marginLeft: '1vw', marginTop: '1vw', backgroundColor: 'gold', borderRadius: '2vw', borderWidth: '0px' }}>
            <Link to="/cameos">
              <p style={{ fontSize: 'max(1.1vw, 10px)' }}>COME AND DISCOVER {bannerBulletSymbol}</p>
            </Link>
            </button>
        </div>
        <br/>
        {/* This component is present in all pages except Feedback */}
        <Link to="/Feedback" className="footer"/>
      </div>
    </>
   );
}
 
export default Home;


/* buttons for dev widgets
<button onClick={() => {alert('You found the first button!')}} style={{background: 'transparent', border: 'none', float: 'right', marginRight: '0.25vw', marginTop: '1.5vw', borderRadius: '10%', padding: '13px'}}></button>
        <button onClick={() => {alert('You found the second button!')}} style={{background: 'transparent', border: 'none', float: 'right', marginTop: '6vw', borderRadius: '10%', padding: '13px'}}></button>
        <button onClick={() => {alert('You found the third button!')}} style={{background: 'transparent', border: 'none', float: 'right', marginRight: '0vw', marginTop: '11vw', borderRadius: '10%', padding: '13px'}}></button>
*/