// import React, { useState } from 'react';
// import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Feedback = () => {
    // const notify = (message) => toast.success(message);
    // const [feedback, setFeedback] = useState('');

    // const handleSubmit = () => {
    //   // Reset the feedback state after submission
    //   setFeedback('');
    //   //TODO: thank the feedback
    //   notify('Thank you for your feedback!');
    // };

    return(
        <div className="content">
            <h2 className='first-element-spacing main-text' style={{paddingBottom: '1vw'}}>
                Contact Us
            </h2>            

            <p className='main-text' style={{paddingTop: '4vh'}}>
                We would love to hear from you. If you have any questions or comments, please send us an email at <i>explorer@visiblelanguage.info</i> . Thank you for helping us to improve Book of Mormon Explorer.
      <br/><br/>We suggest that you add <i>explorer@visiblelanguage.info</i> to your Contacts list to avoid having our response end up in your spam filter.
            </p>


        </div>


    )

}


export default Feedback;

/*            <form 
                className='main-text'
                action="https://formspree.io/f/xdoqlbad"
                method="POST"
                >
                <br/>
                { <label for="feedback">Message</label> }
                <textarea style={{fontSize: '0.75rem'}} 
                id="feedback" name="feedback" rows="5" cols="50" 
                required
                placeholder="Enter your questions or comments here."></textarea>
                <br/>
                <input type="hidden" name="_subject" id="email-subject" value="Feedback Form Submission" />
                <button type="submit" style={{fontSize: '0.75rem', padding: '.2em'}}>Submit Questions or Comments</button>
            </form>
*/
