import { Link } from "react-router-dom";

const About = () => {
  return (  
    <div className="content">
      <h2 className='first-element-spacing main-text' style={{paddingBottom: '1vw'}}>
        Words Matter
      </h2>
      <p className='main-text' style={{paddingBottom: '1vw'}}>
        Critics of the Prophet Joseph Smith who claim him the author of a 
        fictional book with imagined characters would find it difficult to 
        dispute what you are about to discover.
      </p>
      <p className='main-text' style={{paddingBottom: '1vw'}}>
        A thorough analysis of speech patterns and words used shows 
        that each voice you hear in the Book of Mormon is unique. Each person 
        has his or her own viewpoint of the gospel, owing to his or her 
        unique experiences, way of thinking, and way of speaking.
      </p>
      <p className='main-text' style={{paddingBottom: '1vw'}}>
        What are the odds that a 23-year-old with little formal education 
        could invent and keep straight all of these unique differences? 
        The chances are very low. If, on the other hand, he were to translate 
        an ancient record (as he claimed), he would not need to keep them straight. 
        They would speak for themselves, as they do in the Book of Mormon.
      </p>
      <br/>
      <p className='main-text copyright'>
        Website content copyright 2024 VisibleLanguage
      </p>

      {/* <h3 className="first-element-spacing main-text">
        Feedback? Contact us <a href="mailto:feedback@bomextest.org" className="main-banner-link">here</a> (feedback@bomextest.org)
      </h3> */}
      <br/><br/><br/><br/>
      {/* This component is present in all pages except Feedback */}
      <Link to="/Feedback" className="footer"/>
    </div>
  );
}
 
export default About;

/*      
      <p className='main-text' >
        Learn more about how our scholars and researchers were able to chart 
        every word spoken in the Book of Mormon.
      </p>
*/